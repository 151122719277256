import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  timeAlert = 5000;

  constructor() { }

  alert(type: 'error' | 'success', content: string) {
    if (type == 'error') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${content}`,
        timer: this.timeAlert
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: `${content}`,
        timer: this.timeAlert
      })
    }
  }
}
