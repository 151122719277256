<!-- HTML !-->
<button
  name="button"
  [type]="typeBtn"
  class="button"
  role="button"
  [ngClass]="{ bgActive: activeBg }"
>
  @if (showImg) {
    <!-- <img [src]="pathIcon" alt=""> -->
    <img [src]="'../../../assets/icons/Unicolor/' + icon + '-white.svg'" alt="">
  }
  <span>
    {{ contentBtn }}
  </span>
</button>
