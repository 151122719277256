export const patterns = {
  EMAIL: '(^[\\w.%+-]{1,})@(([\\w-]{2,}\\.)+)([\\w]{2,})',
  ONLY_NUMBERS: '([0-9]+)',
  CARD_NUMBERS: '([0-9 ]+)',
  PERCENTAGE: '([0-9.]+)',
  LETTERS_AND_NUMBERS: '([a-zñA-ZÑ0-9]+)',
  ONLY_LETTERS_AND_SPACE: '([a-zñA-ZÑÁÉÍÓÚáéíóú ]+)',
  ALL_VALID_CHARACTERES: '([a-zA-ZáéíóúÁÉÍÓÚäëöïüÄËÏÖÜÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ0-9 .;:,&_ñÑ#%*@$!|"¿?/\n+¡-]+)',
  // WEB_PAGE: "((ht|f)tp(s)?:\/\/)?(w{0,3}\.)?([a-zA-Z0-9_\-\.\:\#\/\~\}]+(\.[a-zA-Z]*))(:[1-9][0-9]{0,4})?(\/[a-zA-Z0-9_\-\.\:\#\/\~\}]*)?(\?[a-zA-Z0-9_=&-]*)?"
  // WEB_PAGE: '((?:https\:\/\/)|(?:http\:\/\/)|(?:www\.))?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{0,1}[a-zA-Z0-9\-\.\?\,\'\/\\\&%\$#\=~]\/?\S+)'
  WEB_PAGE: '^www.[a-z\.\-]+\/?\S+'
}
