import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-btn-normal',
  templateUrl: './btn-normal.component.html',
  styleUrls: ['./btn-normal.component.scss']
})
export class BtnNormalComponent {
  @Input() contentBtn = "";
  @Input() typeBtn: "button" | "submit" = "button";
  @Input() colorBtn = "#a5352a";
  @Input() activeBg = false;
  @Input() showImg = false;
  @Input() icon: '' | 'trash' | 'search' | 'download' = '';
  bgColor = `background: ${this.colorBtn};`;
}
